.custom-table .p-datatable-tbody > tr > td {
    border: none !important;
    border-style: none !important;
}

.custom-table .p-datatable-tbody > tr.p-highlight {
    background-color: var(--primary-color);
    color: #fff;
}

.custom-table tr.p-highlight .pi {
    color: #fff;
}

.custom-button:focus {
    box-shadow: none !important;
}
.custom-checkbox111 input[type="checkbox"]:checked + .p-checkbox-box {
    border-color: white; /* Optional: change border color when checked */
}


@media (max-width: 768px) {
  .responsive-dialog {
    width: 85% !important;
  }
  .responsive-flex {
    /* flex-direction: column; */
    gap: 1rem;
  }
  .responsive-gap {
    gap: 2rem;
  }
  .responsive-input {
    width: calc(100% + 2rem);
  }
  .responsive-button {
    width: 100px !important;
  }
  .p-dialog .p-dialog-content {
    padding: 0 1.5rem 2rem 0rem;
}
}

div.col-3{
  padding: 0;
}
/* .p-inputtext {
  /* margin-top: 5px; */
/* } */ 
/* Default size for Button */

/* Responsive adjustments */
/* @media (max-width: 1400px) {
  .responsive-dialog {
    width: 85% !important;
  }
} */







@media (max-width: 768px) {
    .responsive-dialog {
      width: 85% !important;
    }
    .p-fluid{
      width: 85%!important;

    }
    div[id^="pr_id_"].p-dialog-content {
      padding: 15px 1.5rem 1rem 1.5rem;
    }
    
}

/* TimelineDemo.css */
.image-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 200px; /* Độ rộng tối đa của thẻ div */
    min-width: 250px;
}

.image-container img {
    max-height: 150px; /* Điều chỉnh chiều cao của hình ảnh */
    margin-right: 10px;
}

.customized-timeline .p-timeline-event:nth-child(even) {
    flex-direction: row !important;
}
.customized-timeline
    .p-timeline-event:nth-child(even)
    .p-timeline-event-content {
    text-align: left !important;
}
.customized-timeline .p-timeline-event-opposite {
    flex: 0;
}
.customized-timeline .p-card {
    margin-top: 1rem;
}

.custom-row-class-history {
    height: 3rem;
}

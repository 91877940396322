.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Overlay {
    background: rgba(0, 0, 0, 0.5);
}
.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
.progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 5px;
    width: 110px;
    box-sizing: border-box;
    height: 21px;
}

/* The progress bar itself */
.progress-bar {
    background-color: #278502;
    border-radius: 5px;
    height: 21px;
    display: flex;
    align-items: center;
    color: black;
    /* position: relative; */
    justify-content: space-between;
    /* padding-left: 10px;  */
}
.progress-bar0 {
    background-color: #278502;
    border-radius: 5px;
    height: 21px;
    display: flex;
    align-items: center;
    color: white;
    /* position: relative; */
    justify-content: space-between;

    /* padding-left: 10px;  */
}
.progress-text {
    margin-left: 3px;
}
.progress-text1 {
    margin-left: 3px;
    color: rgb(0, 0, 0);
}
.progress-text2 {
    margin-left: 3px;
    color: rgb(0, 0, 0);
}

.progress-remaining {
    color: black;
    margin-right: 5px;
}
.progress-remaining0 {
    margin-right: 5px;
}
.progress-remaining1 {
    margin-right: 5px;
    color: red;
}
/* div.p-column-filter-add-rule{
    display: none;
}
div.p-column-filter-operator{
    display: none;
}
span.p-dropdown-label.p-inputtext{
    display: none;
} */

/* .p-datatable .p-column-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px; 
  }
  
  span.p-column-title{
    white-space: nowrap;
  } */

@media (max-width: 768px) {
    .responsive-dialog {
      width: 85% !important;
    }
    input#productionPlan.p-inputtext.p-component {
      width: 12rem!important;
    }
    /* .p-dialog .p-dialog-content {
        padding: 0;
    } */
    div#pr_id_8_content.p-dialog-content {
        padding: 1rem;
    }
}


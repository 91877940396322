$primaryColor: #4880FF !default;
$primaryLightColor: #a7f3d0 !default;
$primaryDarkColor: #059669 !default;
$primaryDarkerColor: #047857 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #f0fdfa !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import "../_variables";
@import "./_fonts";
@import "../../../theme-base/_components";
@import "../_extensions";
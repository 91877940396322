.calendar-container {
    color: #0c4da2;
    display: flex;
    align-items: center;
    background-color: white;
    padding-left: 12px;
    border-radius: 4px;
    width: 175px;
    span {
        input {
            border: none;
            border-radius: 0;
            padding: 0 4px;
            width: 95px;
            color: #0c4da2;
            cursor: pointer;
        }
    }
    button {
        background-color: black;
    }
}

@media (max-width: 768px) {
    .chart-factory {
        width: 100% !important;
    }
}
@media (max-width: 575px) {
    .btn-container {
        margin-left: 40px;
    }
}

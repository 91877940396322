.search-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
}

.search-container-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
}

.search-item {
    min-width: 0;
    width: 100%;
}

@media (max-width: 768px) {
    .search-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .search-container-header {
        grid-template-columns: repeat(2, 1fr);
    }
}

.search-item .p-dropdown,
.search-item .p-inputtext {
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .search-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .data-table-container {
        width: 100% !important;
        overflow-x: auto;
        /* width: 100vw !important; */
    }

    .data-table-container .p-datatable {
        min-width: 600px;
    }

    .data-table-container .p-column-header,
    .data-table-container .p-datatable-tbody > tr > td {
        white-space: nowrap;
    }
}
